import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { localStoresCol } from "../../../components/FilteringTable/Columns";
import {
  fetchAllLocalStoresCall,
  toggleDisabledStoreCall,
  updateStoreCall,
  uploadImageToS3Call,
} from "../../../Api/ApiCalls";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { toastOptions } from "../../../config/Constants";
import { useLocation } from "react-router-dom";
import { ApiSearchField } from "../../../components/ApiSearchField";
import { handleError } from "../../../utils/SharedFunctions";
import {MoreInfo} from "../../../components/MoreInfo";
import { TooltipMessagesTypes } from '../../../enums/tooltipMessagesTypes';
import PaginationControls from "../../../components/PaginationControls";

export default function SnipTechStore() {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(undefined);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(undefined);
  const [id, setId] = useState(undefined);
  const [title, setTitle] = useState(undefined);
  const [shortDes, setShortDes] = useState(undefined);
  const [fullDes, setFullDes] = useState(undefined);
  const [background, setBackground] = useState(undefined);
  const [disabled, setDisabled] = useState(undefined);
  const [appWeight, setAppWeight] = useState(undefined);
  const [webWeight, setWebWeight] = useState(undefined);
  const [isFreeToUse, setIsFreeToUse] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setColumns(localStoresCol);
  }, []);
  
  useEffect(() => {
    getSnipTechStores(1);
  }, [location.search, searchQuery]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    getSnipTechStores(page);
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns: ["background"] },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Stores</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <ApiSearchField
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <table {...getTableProps()} className="table dataTable display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="cursor-pointer"
                            onClick={() => openModalEdit(row.original)}
                          >
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* This is only for footer if u require */}
            <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        size="xl"
        show={showModal}
        onHide={resetModalState}
      >
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Advertiser</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={resetModalState}
                  data-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="row">
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Id</label>
                        <div className="contact-name">
                          <span className="validation-text">{id}</span>
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Title</label>
                        <div className="contact-name">
                          <span className="validation-text">{title}</span>
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Short Description
                        </label>
                        <div className="contact-name">
                          <span className="validation-text">{shortDes}</span>
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Full Description
                        </label>
                        <div className="contact-name">
                          <span className="validation-text">{fullDes}</span>
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500 d-flex align-items-center">
                          Background Image
                          <MoreInfo content={[TooltipMessagesTypes.DIMENSIONS_1024_768, TooltipMessagesTypes.FORMAT_JPG, TooltipMessagesTypes.SIZE_250]} />
                        </label>
                        <div className="row" style={{ padding: "20px" }}>
                          <div className="col-9">
                            <input
                              className="form-control form-control-lga"
                              type="file"
                              accept=".jpg, .jpeg"
                              onChange={handleFileSelect}
                            />
                          </div>
                          {file && (
                            <div className="col-3">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={uploadToS3}
                              >
                                Upload
                              </button>
                            </div>
                          )}
                        </div>

                        <div className="image-container">
                          {
                            background && (
                                <img
                                  className="img"
                                  src={background}
                                  alt="Description of the image"
                                />
                            )
                          }
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <form>
                          <div className="row">
                            <label className="text-black font-w500">
                              Priority
                            </label>
                            <div className="form-group mb-3 col-sm-10 col-md-6">
                              <label className="text-black font-w500">
                                App Weight
                              </label>
                              <div className="contact-name">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="appWeight"
                                  required="required"
                                  value={appWeight}
                                  min={1}
                                  max={100}
                                  onChange={(val) =>
                                    setAppWeight(
                                      validateWeight(val.target.value)
                                    )
                                  }
                                />
                                <span className="validation-text"></span>
                              </div>
                            </div>
                            <div className="form-group mb-3 col-sm-10 col-md-6">
                              <label className="text-black font-w500">
                                Web Weight
                              </label>
                              <div className="contact-name">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="webWeight"
                                  required="required"
                                  value={webWeight}
                                  min={1}
                                  max={100}
                                  onChange={(val) =>
                                    setWebWeight(
                                      validateWeight(val.target.value)
                                    )
                                  }
                                />
                                <span className="validation-text"></span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="help-text small">
                              1 = the top of the list
                              <br />
                              70 = the standard value for the records
                              <br />
                              100 = the end of the list
                            </p>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-sm-10 col-md-12">
                              <div className="d-flex justify-content-between">
                                <div className="form-group mb-3">
                                  <label className="text-black font-w500">
                                    Can be used in limited mode? <span className="text-primary">(Free to use)</span>
                                  </label>
                                  <input
                                      type="checkbox"
                                      name="isFreeToUse"
                                      checked={isFreeToUse}
                                      className="form-check-input ms-2"
                                      onChange={(val) => setIsFreeToUse(val.target.checked)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={updatePriority}
                            >
                              Save
                            </button>
                            <button
                              className={
                                disabled
                                  ? "btn btn-secondary"
                                  : "btn btn-danger"
                              }
                              onClick={handleToggleDisabling}
                            >
                              {disabled ? "Enable" : "Disable"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );

  function validateWeight(value) {
    if (!value || value < 1) return 1;
    if (value > 100) return 100;
    value = parseInt(value, 10);
    return value;
  }

  function openModalEdit(item) {
    setId(item.id);
    setTitle(item.title);
    setShortDes(item.nl.shortDes);
    setFullDes(item.nl.fullDes);
    setDisabled(item.disabled);
    setAppWeight(item.appWeight);
    setWebWeight(item.webWeight);
    setIsFreeToUse(item.isFreeToUse);
    setShowModal(true);
    setBackground(item.background);
  }

  function getFilters() {
    const culture = searchParams.get("culture");
    const apiFilters = [`culture=${culture}`];
    if (searchQuery?.length) {
      apiFilters.push(`nameWildcard=${encodeURIComponent(searchQuery)}`);
    }
    return apiFilters.join("&");
  }

  function getSnipTechStores(page) {
    fetchAllLocalStoresCall(page, 10, getFilters())
      .then((result) => {
        setData(result?.items);
        setTotalPages(result.totalPages);
        setCurrentPage(result.page);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function resetModalState() {
    setShowModal(false);
    setFile(undefined);
  }

  function validateImage(image) {
    // Check file extension
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = image.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setFile(undefined);
      toast.error(
        "Please select a valid image file (jpg or png).",
        toastOptions
      );
      return false;
    }

    // Check file size
    const maxSizeKB = 500;
    if (image.size > maxSizeKB * 1024) {
      setFile(undefined);
      toast.error("Image size exceeds 250KB limit.", toastOptions);
      return false;
    }

    // Check dimensions
    const maxWidth = 1024;
    const maxHeight = 768;
    const img = new Image();
    img.src = URL.createObjectURL(image);
    img.onload = () => {
      if (img.width > maxWidth || img.height > maxHeight) {
        setFile(undefined);
        toast.error(
          "Image dimensions must be 1024x768 or smaller.",
          toastOptions
        );
        return false;
      }
    };
    setFile(image);
    return true;
  }

  function handleFileSelect(e) {
    const file = e.target.files[0];
    if (file) {
      if (!validateImage(file)) return;
      const reader = new FileReader();
      reader.onload = (event) => {
        setBackground(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  function uploadToS3() {
    const formData = new FormData();
    if (!file) {
      toast.error("No image was uploaded!", toastOptions);
      return;
    }
    if (!validateImage(file)) return;
    formData.append("file", file);
    uploadImageToS3Call(id, formData)
      .then((success) => {
        toast.success(success.data?.message, toastOptions);
        setBackground(`${success?.endpoint}?cacheBuster=${Math.random()}`);
        setData((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, background: success?.endpoint } : item
            )
        );
      })
      .catch((error) => {
        toast.error(error.response?.data?.error, toastOptions);
      })
      .finally(() => setFile(undefined));
  }

  function handleToggleDisabling(e) {
    e.preventDefault();
    const payload = {
      disabled: !disabled,
    };
    toggleDisabledStoreCall(id, payload)
      .then((res) => {
        setShowModal(false);
        toast.success(
            "The store has been updated successfully.",
            toastOptions
        );
        setDisabled(res.disabled);
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.id === res.id) {
              return { ...item, disabled: res.disabled };
            }
            return item;
          });
        });
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function updatePriority(e) {
    e.preventDefault();
    const payload = {
      isFreeToUse: isFreeToUse,
      appWeight: appWeight,
      webWeight: webWeight,
    };
    updateStoreCall(id, payload)
      .then((res) => {
        setAppWeight(res.appWeight);
        setWebWeight(res.webWeight);
        setIsFreeToUse(res.isFreeToUse);
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.id === res.id) {
              return {
                ...item,
                appWeight: res.appWeight,
                webWeight: res.webWeight,
                isFreeToUse: res.isFreeToUse,
              };
            }
            return item;
          });
        });
        setShowModal(false);
        toast.success(
          "The store priority has been updated successfully.",
          toastOptions
        );
      })
      .catch((error) => {
        handleError(error);
      });
  }
}
