/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../config/context/ThemeContext";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);


  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }
        handleheartBlast.addEventListener('click', heartBlast);

  }, []);
 //For scroll
 const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
          <MM className="metismenu" id="menu">

              {/*Dashboard*/}
              <li className={`${path.includes("dashboard") ? "mm-active" : ""}`}>
                  <Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard">
                      <i className="fas fa-home"></i>
                      <span className="nav-text">Dashboard</span>
                  </Link>
              </li>

              {/*Search-user*/}
              <li className={`${path.includes("search-user") || path.includes("users-subscriptions") ? "mm-active" : ""}`}>
                  <Link className={`${path === "search-user" ? "mm-active" : ""}`} to="/search-user">
                      <i className="la la-users fs-26"></i>
                      <span className="nav-text">User</span>
                  </Link>
                  <ul>
                      <li>
                          <Link className={`${path === "search-user" ? "mm-active" : ""}`}
                                to="/search-user">
                              <span className="nav-text">Search</span>
                          </Link>
                      </li>
                      {/*<li >*/}
                      {/*    <Link className={`${path === "users-subscriptions" ? "mm-active" : ""}`}*/}
                      {/*          to="/users-subscriptions">*/}
                      {/*        /!*<i className="la la-box-open fs-26"></i>*!/*/}
                      {/*        <span className="nav-text">Subscriptions</span>*/}
                      {/*    </Link>*/}
                      {/*</li>*/}
                    </ul>
              </li>

              <li className={`${path.includes("sniptech-stores") || path.includes("notification") || path.includes("knaek-manager")? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#">
                      <i className="fas fa-mobile-alt"></i>
                      <span className="nav-text">App</span>
                  </Link>
                  <ul>
                      <li>
                          <Link className={`${path === "notification-overview" ? "mm-active" : ""}`}
                                to="/notification-overview">
                              <span className="nav-text">Notifications</span>
                          </Link>
                      </li>
                      <li >
                          <Link className={`${path === "knaek-manager" ? "mm-active" : ""}`}
                                to="/knaek-manager">
                              {/*<i className="la la-box-open fs-26"></i>*/}
                              <span className="nav-text">Instore</span>
                          </Link>
                      </li>
                      <li className={`${path.includes("sniptech-stores") ? "mm-active" : ""}`}>
                          <Link className="has-arrow" to="#">
                              {/*<i className="la la-institution fs-26"></i>*/}
                              <span className="nav-text">Online</span>
                          </Link>
                          <ul className={`${path.includes("sniptech-stores") ? "mm-show" : ""}`} >
                              <li><Link className={`${path.includes('nl-NL') ? "mm-active" : ""}`}
                                        to="/sniptech-stores?culture=nl-NL"> NL Stores</Link></li>
                              <li><Link className={`${path.includes('nl-BE') ? "mm-active" : ""}`}
                                        to="/sniptech-stores?culture=nl-BE"> BE Stores</Link></li>
                          </ul>
                      </li>
                  </ul>
              </li>
              <li className={`${path.includes("orders") || path.includes("introduction-events-overview") ? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#">
                      <i className="fas fa-globe"></i>
                      <span className="nav-text">Website</span>
                  </Link>
                  <ul>
                    <li >
                        <Link className={`${path === "orders" ? "mm-active" : ""}`} to="/orders">
                            {/*<i className="la la-dropbox fs-26"></i>*/}
                            <span className="nav-text">Orders</span>
                        </Link>
                    </li>
                    <li >
                        <Link className={`${path === "introduction-events-overview" ? "mm-active" : ""}`}
                              to="/introduction-events-overview">
                            {/*<i className="la la-box-open fs-26"></i>*/}
                            <span className="nav-text">Intro pages</span>
                        </Link>
                    </li>
                  </ul>
              </li>


              {/*<li className={`${path.includes("notification") ? "mm-active" : ""}`}>*/}
              {/*   <Link className="has-arrow" to="#">*/}
              {/*       <i className="fas flaticon-381-notification"></i>*/}
              {/*       <span className="nav-text">Notifications</span>*/}
              {/*   </Link>*/}
              {/*   <ul>*/}
              {/*       <li><Link className={`${path === "notification-overview" ? "mm-active" : ""}`}*/}
              {/*                 to="/notification-overview"> Notification</Link></li>*/}
              {/*   </ul>*/}
              {/*</li>*/}


              {/*Notifications*/}


              {/*/!*Actions*!/*/}
              {/*<li className={`${path.includes("actions") ? "mm-active" : ""}`}>*/}
              {/*    <Link className={`${path === "actions" ? "mm-active" : ""}`} to="/actions-overview">*/}
              {/*        <i className="fas fa-percentage"></i>*/}

              {/*        <span className="nav-text">Actions</span>*/}
              {/*    </Link>*/}
              {/*</li>*/}

              {/*educational-institution*/}
              <li className={`${path.includes("educational-institution") ? "mm-active" : ""}`}>
                  <Link className={`${path === "educational-institution" ? "mm-active" : ""}`}
                        to="/educational-institution-overview">
                      <i className="la la-school fs-26"></i>
                      <span className="nav-text">Educational institutions</span>
                  </Link>
              </li>
              <li className={`${path.includes("knaekid") || path.includes("mandate-number") || path.includes("membership")? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#">
                      <i className="fas fa-upload"></i>
                      <span className="nav-text">Bulk Uploads</span>
                  </Link>
                  <ul>
                      <li className={`${path.includes("knaekid") ? "mm-active" : ""}`}>
                          <Link className="has-arrow" to="#">
                              {/*<i className="fas flaticon-381-id-card"></i>*/}
                              <span className="nav-text">Knaek-ID’s</span>
                          </Link>
                          <ul className={`${path.includes("knaekid") ? "mm-show" : ""}`} >
                              <li><Link className={`${path === "knaekid-extend" ? "mm-active" : ""}`}
                                        to="/knaekid-extend">Update</Link></li>
                              <li><Link className={`${path === "knaekid-generate" ? "mm-active" : ""}`}
                                        to="/knaekid-generate">Add</Link></li>
                              <li><Link className={`${path === "knaekid-search" ? "mm-active" : ""}`}
                                        to="/knaekid-search">Search</Link></li>

                          </ul>
                      </li>
                      <li >
                          <Link className={`${path === "mandate-number" ? "mm-active" : ""}`} to="/mandate-number">
                              {/*<i className="la la-location-arrow fs-26"></i>*/}
                              <span className="nav-text">Mandate numbers</span>
                          </Link>
                      </li>
                      <li className={`${path.includes("membership") ? "mm-active" : ""}`}>
                          <Link className="has-arrow r-1" to="#">
                              {/*<i className="la la-user-md fs-26"></i>*/}
                              <span className="nav-text">Mandate system</span>
                          </Link>
                          <ul>
                              <li><Link className={`${path === "membership-registration-system-user" ? "mm-active" : ""}`}
                                        to="/membership-registration-system-user">User registration</Link>
                              </li>
                              {/*<li><Link className={`${path === "membership-form-order" ? "mm-active" : ""}`}*/}
                              {/*          to="/membership-form-order"> Mandate overview</Link></li>*/}

                          </ul>
                      </li>
                  </ul>
              </li>

              {/*<li className={`${path.includes("unsubscribe") ? "mm-active" : ""}`}>*/}
              {/*    <Link className="has-arrow" to="/unsubscribe">*/}
              {/*        <i className="la la-undo fs-26"></i>*/}
              {/*        <span className="nav-text">Unsubscribe members</span>*/}
              {/*    </Link>*/}
              {/*    <ul>*/}
              {/*        <li><Link className={`${path === "actions-overview" ? "mm-active" : ""}`}*/}
              {/*                  to="/unsubscribe"> Unsubscribe members</Link></li>*/}
              {/*        <li><Link className={`${path === "actions-extend" ? "mm-active" : ""}`}*/}
              {/*                  to="/unsubscribe-events"> Unsubscribe events</Link></li>*/}

              {/*    </ul>*/}
              {/*</li>*/}



              {/*introduction-events-overview*/}

              {/*Locations
              <li className={`${path.includes("locations") ? "mm-active" : ""}`}>
                  <Link className={`${path === "locations" ? "mm-active" : ""}`} to="/locations">
                      <i className="la la-location-arrow fs-26"></i>
                      <span className="nav-text">Locations</span>
                  </Link>
              </li>*/}


              <li className={`${path.includes("admin-overview") ? "mm-active" : ""}`}>
                  <Link className={`${path === "admin-overview" ? "mm-active" : ""}`} to="/admin-overview">
                      <i className="la la-users fs-26"></i>
                      <span className="nav-text">Admin Users</span>
                  </Link>
              </li>



          </MM>

          <div className="copyright">
              <p className="fs-14 fw-bold text-center">Made with <span className="heart"></span> by <a
                  href="https://it-plus24.com" target="_blank" rel="noreferrer">IT-plus24</a></p>
          </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
