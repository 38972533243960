import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";

export default function IntroEventQR({item, slug, setSlug, showEdit, country}) {
    const [link, setLink] = useState("");
    const [error, setError] = useState("");
    useEffect(() => {
        if(item){
            qeCodeLinkGenerator(item)
        }
        else {
            const baseUrl = generateBaseUrl("NL")
            setLink(baseUrl)
        }
    }, [item]);

    const [options, setOptions] = useState({
        width: 300,
        height: 300,
        type: "svg",
        data: link,
        image: "/favicon.png",
        margin: 10,
        qrOptions: {
            typeNumber: 0,
            mode: "Byte",
            errorCorrectionLevel: "Q",
        },
        imageOptions: {
            hideBackgroundDots: true,
            imageSize: 0.4,
            margin: 20,
            crossOrigin: "anonymous",
        },
        dotsOptions: {
            color: "#222222",
            type: "rounded",
        },
        backgroundOptions: {
            color: "#fff",
        },
        cornersSquareOptions: {
            color: "#222222",
            type: "extra-rounded",
        },
        cornersDotOptions: {
            color: "#222222",
            type: "dot",
        },
    });

    const [fileExt, setFileExt] = useState("svg");
    const qrCodeRef = useRef(null);
    const ref = useRef(null);

    useEffect(() => {
        if (country) {
            const baseUrl = generateBaseUrl(country);
            setLink(`${baseUrl}${slug}`);
        }
    }, [country, slug]);

    useEffect(() => {
        qrCodeRef.current = new QRCodeStyling(options);
    }, [options, item]);

    useEffect(() => {
        if (ref.current && qrCodeRef.current) {
            qrCodeRef.current.append(ref.current);
        }
    }, [ref, item, options]);

    useEffect(() => {
        if (qrCodeRef.current) {
            qrCodeRef.current.update(options);
        }
    }, [options, item]);

    const generateBaseUrl = (data) => {
        return data?.toString().includes("BE")
            ? "https://www.knaek.be/intro/"
            : "https://www.knaek.nl/intro/";
    };

    const handleSlugChange = (event) => {
        const newSlug = event.target.value.replace(/\s+/g, "-");
        if (newSlug.includes("%")) {
            setError("The % character is not allowed in the URL.");
            return;
        } else {
            setError("");
        }
        setSlug(newSlug);
        const baseUrl = generateBaseUrl(item?.country)

        const newFullUrl = `${baseUrl}${encodeURIComponent(newSlug)}`;
        setLink(newFullUrl);
        setOptions((prevOptions) => ({ ...prevOptions, data: newFullUrl }));
    };

    const onExtensionChange = (event) => {
        const newExtension = event.target.value;
        setFileExt(newExtension);
    };

    const onDownloadClick = () => {
        if (qrCodeRef.current) {
            qrCodeRef.current.download({
                extension: fileExt,
            });
        }
    };

    return (
        <div className="col-12 pb-5">
            <section className="row">
                <div ref={ref} className="col-12" />
                <div className="col-6 mt-5">
                    <div
                        style={styles.inputWrapper}>
                        <span style={{color: "gray"}}>
                            {link.split("/").slice(0, -1).join("/") + "/"}
                        </span>
                        <input
                            type="text"
                            value={slug}
                            onChange={handleSlugChange}
                            style={styles.linkInput}
                        />
                    </div>
                    {error && <p style={{ color: "red", marginTop: "5px" }}>{error}</p>}
                </div>
                {showEdit && (
                    <div className="col-6 mt-5 d-flex justify-content-between">
                        <select onChange={onExtensionChange} value={fileExt} className={"form-control w-75"}>
                            <option value="svg">SVG</option>
                            <option value="png">PNG</option>
                            <option value="jpeg">JPEG</option>
                            <option value="webp">WEBP</option>
                        </select>
                        <button type="button" onClick={onDownloadClick} className="btn btn-danger">
                            <i className="flaticon-delete-1"></i> Download
                        </button>
                    </div>
                )}


            </section>
        </div>
    );


    function qeCodeLinkGenerator(data) {
        if (data) {
            const baseUrl = generateBaseUrl(data?.country)
            const linkLocal = `${baseUrl}${encodeURIComponent(data.slug || "")}`;
            setLink(linkLocal)
            setOptions((prevOptions) => ({...prevOptions, data: linkLocal}));
        }

    }
};


const styles = {
    inputWrapper: {
        display: "flex",
        alignItems: "center",
        border: "2px solid pink",
        borderRadius: "10px",
        padding: "10px",
    },
    linkInput: {
        border: "none",
        outline: "none",
        flex: 1,
    }
};
