 import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p className="fs-14 fw-bold text-center">Made with <span className="heart"></span> by    <a href="https://it-plus24.com" target="_blank" rel="noreferrer">IT-plus24</a></p>
      </div>
    </div>
  );
};

export default Footer;
