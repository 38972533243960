import React, { useEffect, useState } from "react";
import Section from "./common/Section";
import DropdownSection from "./common/DropDownSection";
import Divider from "./common/Divider";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { PaymentRequestStatus } from "../../../enums/PaymentRequestStatus";
import {
  accountDataLabel,
  appLabel,
  labelMappings,
  personalDataLabels,
  studyLabels,
  subscriptionLabel,
} from "./constant";
import Button from "./common/Button";
import { extractData } from "./helpers";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import {handleError, hasAValue} from "../../../utils/SharedFunctions";
import {
  getMandateNumberImage,
  getUserDetailsById,
  updateUser,
  getUserPayments,
  stopUserPaymentReminder
} from "../../../Api/ApiCalls";
import EraseUserModal from "./modals/EraseUserModal";
import CancelSubscriptionModal from "./modals/CancelSubscritionModal";
import { Modal } from "react-bootstrap";
import EntityModal from "./modals/EntityModal";
import Entity from "./common/Entity";
import WhiteListUserModal from "./modals/WhiteListUser";
import MandateModal from "./modals/MandateModal";
import Payments from "./common/Payments";


const UserInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showKWMModal, setShowKWMModal] = useState(false);
  const [showKWMPaymentStatus, setShowKWMPaymentStatus] = useState(null);
  const [kwmId, setkwmId] = useState(null);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMembershipCancelled, setIsMembershipCancelled] = useState(false);
  const [ mandateImage, setMandateImage ] = useState("");
  const [ paymentsList, setPaymentsList ] = useState([]);
  const [ mandateFileType, setMandateFileType ] = useState("");

  const [entities, setEntities] = useState({
    personalData: [],
    study: [],
    app: [],
    account: [],
    subscription: [],
  });
  const { userId } = useParams();
  useEffect(() => {
    setIsLoading(true);
    getUserDetailsById(userId)
      .then((res) => {
        setUserData(res);
        if (res?.channel?.parent === "ANALOGUE" && res?.mandateNumber) {
          getMandateNumberImage(res?.mandateNumber)
            .then((res) => {
              setMandateImage(res.presignedUrl)
              setMandateFileType(res.fileType)
            })
            .catch((err) => handleError(err));
        }
        setIsLoading(false);
      })
      .catch((err) => handleError(err));

      getUserPayments(userId)
      .then( (res) => {
        setPaymentsList(res)
      })
  }, [userId]);

  useEffect(() => {
    if (userData) {
      const updatedEntities = {
        personalData: personalDataLabels.map((label) => ({
          label: label,
          data: extractData(label, labelMappings, userData),
        })),
        study: studyLabels.map((label) => ({
          label: label,
          data: extractData(label, labelMappings, userData),
        })),
        app: appLabel.map((label) => ({
          label: label,
          data: extractData(label, labelMappings, userData),
        })),
        account: accountDataLabel.map((label) => ({
          label: label,
          data: extractData(label, labelMappings, userData),
        })),
        subscription: userData?.subscriptions
          .map((subscription, subscriptionIndex) =>
            subscriptionLabel.map((label) => ({
              label: label,
              data: extractData(label, labelMappings, subscription),
              subscriptionIndex: subscriptionIndex + 1,
            }))
          )
          .flat(),
      };
      setEntities(updatedEntities);
    }
  }, [userData]);


const confirmEndKwm = async(id) => {
  setkwmId(id);
  setShowKWMModal(true)

}
const stopPaymentReminders = async () => {
  setShowKWMModal(false)
  stopUserPaymentReminder(userId, kwmId, showKWMPaymentStatus)
  .then(res => {
    toast.success('KWM for this payment ended successfully !')
    getUserPayments(userId)
    .then( (res) => {
      setPaymentsList(res)
    })
  })
}
    const handleUpdateUser = async (modifiedData, isCrucialDataModified) => {
        // Check if no data was modified
        if (Object.keys(modifiedData).length === 0) {
            toast.error("Nothing is changed");
            return;
        }

        // Helper function to convert property values to numbers
        const convertToNumber = (key) => {
            if (modifiedData[key]) {
                modifiedData[key] = Number(modifiedData[key]);
            }
        };

        // Convert specific fields to numbers
        convertToNumber("institutionId");
        convertToNumber("cityId");

        // Define the update operation in its own async function
        const confirmUpdate = async () => {
            try {
                setIsUpdating(true);

                let updatedData = modifiedData;
                if ("phoneNumber" in modifiedData || "countryCode" in modifiedData) {
                    // Ensure both phoneNumber and countryCode are included in the update
                    updatedData = {
                        ...modifiedData,
                        phoneNumber: modifiedData.phoneNumber || userData.phoneNumber,
                        countryCode: modifiedData.countryCode || userData.countryCode
                    };
                }
                // Try to update the user
                await updateUser(updatedData, userData?.id);
                // Success handling for the update
                setShowModal(false);
                toast.success("User updated successfully");
                const userDetails = await getUserDetailsById(userId);
                setUserData(userDetails);
            } catch (err) {
                toast.error(err.response?.data?.message || "An error occurred");
            } finally {
                Swal.close();
                // Ensure loading and updating flags are reset correctly
                setIsLoading(false);
                setIsUpdating(false);
            }
        };

        // Confirm before updating crucial data
        if (isCrucialDataModified) {
            const shouldUpdate = await swal({
                title: "Are you sure?",
                text: "You want to update this crucial data for this user!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });

            if (shouldUpdate) {
                await confirmUpdate();
            }
        } else {
            await confirmUpdate();
        }
    };


    useEffect(() => {
      if (
          userData?.subscriptions &&
          userData?.subscriptions?.length <= 0
      ) {
          setIsMembershipCancelled(true);
      }
    else if (userData?.subscriptions) {
      const hasCancelled = JSON.stringify(userData?.subscriptions).includes(
        "CANCELLED"
      );
      setIsMembershipCancelled(hasCancelled);
    }
  }, [userData]);

  function openModalEdit() {
    setShowModal(true);
    setShowEdit(true);
  }

  const canBeWhitelisted = () => {
    if ((!userData || !userData.subscriptions || userData.subscriptions.length === 0 ) ) {
        return true;
    }
    if( userData?.email.toString().toLowerCase().includes("$")){
        return false;
    }

    const lastSubscription = userData.subscriptions[userData.subscriptions.length - 1];

    return lastSubscription.status !== "ACTIVE"
}

    const canBeErased = () => {
        if(userData?.subscriptions.length > 0 && userData?.subscriptions[userData?.subscriptions?.length -1].status === "ACTIVE") return false;
        if( hasAValue(userData?.deletedAt)) return false;
        return true;
    };


  const generateButtonWithModals = () => {
    return (
      <>
        <Button
          text="Edit User"
          variant="blue"
          data-toggle="modal"
          onClick={() => {
            openModalEdit(userData);
          }}
          iconClassName="fas fa-solid fa-user"
        />
        <CancelSubscriptionModal
            setUserData={setUserData}
            id="3"
          ModalTrigger={
            <Button
              disabled={isMembershipCancelled}
              className={"disabled"}
              text={`Cancel Subscription`}
              variant="red"
              data-toggle="modal"
              iconClassName="fas fa-solid fa-ban"
            />
          }
          userData={userData}
        />
        <WhiteListUserModal
          id="4"
          userData={userData}
          setUserData={setUserData}
          canBeWhitelisted={canBeWhitelisted()}
          ModalTrigger={
            <Button
              text="Whitelist User"
              variant="yellow"
              data-toggle="modal"
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ width: "20px", paddingRight: "5px", color: "white" }}
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#ffffff"
                    d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"
                  />
                </svg>
              }
            />
          }
        />
        <MandateModal
            setUserData={setUserData}
            id="5"
          ModalTrigger={
            <Button
              text="Mandate"
              variant="darkGray"
              svg={
                <svg
                  style={{ width: "20px", paddingRight: "5px", color: "white" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#ffffff"
                    d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 0v64h64V96H64zm384 0H192v64H448V96zM64 224v64h64V224H64zm384 0H192v64H448V224zM64 352v64h64V352H64zm384 0H192v64H448V352z"
                  />
                </svg>
              }
              disabled={mandateImage === ""}
            />
          }
          type={mandateFileType}
          src={mandateImage}
        />
        <EraseUserModal
            setUserData={setUserData}
            id="2"
          ModalTrigger={
            <Button
              text="Erase User"
              variant="cyan"
              data-toggle="modal"
              iconClassName="fas fa-solid fa-trash"
              disabled={!canBeErased()}
            />
          }
          userData={userData}
        />{" "}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header">
                  <h1
                    className="card-title text-primary"
                    style={{ fontSize: "25px" }}
                  >
                    User Details
                  </h1>
                  <div className="d-flex gap-2">
                    {generateButtonWithModals()}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    style={{
                      marginTop: "-20px",
                    }}
                  >
                    <Section
                      title="Personal data"
                      deletedAt={userData?.deletedAt}
                      entities={entities.personalData}
                    />
                  </div>
                  <Divider />
                  <div className="d-flex">
                    <div className="w-50">
                      <div
                        className="h3 font-weight-bold col-3 text-primary"
                        style={{ marginTop: "12px" }}
                      >
                        Study
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        {entities?.study?.map((entity, index) => (
                          <Entity
                            key={index}
                            label={entity.label}
                            data={entity.data}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="w-50">
                      <div
                        className="h3 font-weight-bold col-3 text-primary"
                        style={{ marginTop: "12px" }}
                      >
                        App
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        {entities?.app.map((entity, index) => (
                          <Entity
                            key={index}
                            label={entity.label}
                            data={entity.data}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <Divider />{" "}
                  <div style={{ marginTop: "10px" }}>
                    <Section
                      title="Account Data"
                      entities={entities?.account}
                    />
                  </div>
                  <Divider />{" "}
                  {userData?.subscriptions?.map((_, index) => (
                    <DropdownSection
                      key={index}
                      title={`Subscription ${
                        userData?.subscriptions.length - index
                      }`}
                      entities={entities?.subscription?.filter(
                        (entity) => entity.subscriptionIndex === index + 1
                      )}
                    />
                  ))}
                  <div className="d-flex my-3">
                    <Divider />{" "}
                  </div>
                  <Payments setShowKWMPaymentStatus={setShowKWMPaymentStatus} payments={paymentsList} userId={userId} confirmEndKwm={confirmEndKwm} />
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade"
            size={"md"}
            show={showKWMModal}
            onHide={() => setShowKWMModal(false)}
          >
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle" style={{color: "rgb(249, 98, 159)"}}>
                      Do you really want to end KWM for user?
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowKWMModal(false)}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body" style={{color: "black"}}>
                      <div className="d-flex flex-column ">
                        <p>User will receive no more emails as text messages with payment requests.</p>
                        <p>However, the subscription will NOT automatically be canceled.</p>
                        <p>You can change the payment status from these options:</p>
                        <div>
                          <select name="paymentStatus" className="form-control" value={showKWMPaymentStatus} onChange={(e) => setShowKWMPaymentStatus(e.target.value)}>
                              {
                                  Object.entries(PaymentRequestStatus).map(status => {
                                      return  <option value={status[0]}> {status[1]} </option>
                                  })
                              }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() => stopPaymentReminders()} >End KWM for Payment</button>
                      <button type="button" class="btn btn-warning" data-dismiss="modal" onClick={() => setShowKWMModal(false)}>Close</button>
                    </div>
            </Modal>
          <Modal
            className="modal fade"
            size={"xl"}
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <div className="" role="document">
              <div className="">
                <form>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Update User</h4>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowModal(false)}
                      data-dismiss="modal"
                    ></button>
                  </div>
                  <EntityModal
                    userData={userData}
                    submitUpdateUser={handleUpdateUser}
                    showEdit={showEdit}
                    isUpdating={isUpdating}
                    setShowModal={setShowModal}
                  />
                </form>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default UserInfo;
