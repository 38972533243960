import Entity from "./Entity";
import { format } from "date-fns";

const Section = ({ title, entities, onUpdate, editable = true, deletedAt = '' }) => {

  const handleEntityUpdate = (label, newData) => {
    onUpdate(label, newData);
  };

  return (
    <div className="d-flex flex-column w-100 ">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="h3 font-weight-bold text-primary">
          {title}
        </div>
        {deletedAt && (
          <span className="badge badge-danger">
            Deleted at {format(new Date(deletedAt), 'dd-MM-yyyy')}
          </span>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        {entities.map((entity, index) => (
          <Entity
            key={index}
            onUpdate={handleEntityUpdate}
            editable={editable}
            {...entity}
          />
        ))}
        <div className="col-3"></div>
        <div className="col-3"></div>
      </div>
    </div>
  );
};

export default Section;
