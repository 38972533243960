export const extractData = (
  label,
  dataMappings,
  userData,
  defaultValue = "-"
) => {
  const mapping = dataMappings[label];
  if (!userData) {
   return defaultValue;
  }
  switch (label) {
    case "Commencing date":
    case "Cancellation date":
    case "Expiration date":
    case "Birthdate":
      return transformDate(userData[mapping], false) || defaultValue;
    case "Institution":
      return userData?.education?.institutionName || "-";
    case "Student City":
      return userData?.cityName || "-";
    case "Education Level":
      return userData?.education?.eductionLevelName || "-";
    case "Knaek-ID":
      return userData?.knaekCode?.code || "-";
    case "Knaek-ID expiration date":
      return transformDate(userData?.knaekCode?.expirationDate, true) || "-";
    case "Credit Card":
      return userData?.paymentAccountDetails?.creditCardNumber || "-";
    case "BIC":
      return userData?.paymentAccountDetails?.bic || "-";
    case "Bank account name":
      return userData?.paymentAccountDetails?.bankAccountName || "-";
    case "IBAN":
      return (
        {
          data: userData?.paymentAccountDetails?.iban,
          verified: userData?.dataVerificationStatus?.isIbanVerified,
        } || "-"
      );
    case "Channel":
      return userData?.channel?.parent || "-";
    case "Phone Number":
      return (
        {
          data: userData?.countryCode + userData?.phoneNumber || "-",
          verified: userData?.dataVerificationStatus?.isPhoneNumberVerified,
        } || "-"
      );
    case "Email":
      return (
        {
          data: userData?.email,
          verified: userData?.dataVerificationStatus?.isEmailVerified,
        } || "-"
      );
    case "Student Email":
      return (
        {
          data: userData?.studentEmail,
          verified: userData?.dataVerificationStatus?.isStudentEmailVerified,
        } || "-"
      );
    case "Price":
      return userData.price
    case "Payment status":
          return userData.paymentStatus
    default:
      return mapping ? userData[mapping] || defaultValue : defaultValue;
  }
};

export const transformDate = (dateString, justDate) => {
  if (!dateString) return "-";

  const formattedDate = new Date(dateString).toLocaleDateString("nl-NL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  if (justDate) {
    return formattedDate;
  } else {
    const formattedTime = new Date(dateString).toLocaleTimeString("nl-NL");
    return `${formattedDate} ${formattedTime}`;
  }
};

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const removeHoursFromDateString = (dateString) => {
  if (dateString?.match(/\d{1,2}:\d{1,2}:\d{1,2}$/)) {
    return dateString?.replace(/\s\d{1,2}:\d{1,2}:\d{1,2}$/, "");
  }
  return dateString;
};
