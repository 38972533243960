export default function  PaginationControls  ({
                                currentPage,
                                totalPages,
                                pageSize,
                                onPageChange,
                                onPageSizeChange,
                                pageSizeOptions = [25, 50]
                            }) {
    const isDisabled = totalPages === 0;

    return (
        <>
            {/* Footer Info */}
            <div className="d-flex justify-content-between">
                <span>
                  Page <strong>{isDisabled ? "0 of 0" : `${currentPage} of ${totalPages}`}</strong>
                </span>

                {/* Page Size Selector */}
                {pageSize && (<div className="mx-2">
                    <label>
                        <select
                            className="form-select form-select-lg"
                            value={pageSize}
                            onChange={(e) => onPageSizeChange(Number(e.target.value))}
                        >
                            {pageSizeOptions.map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>)}

            </div>

            {/* Pagination Buttons */}
            <div className="text-center mb-3">
                <div className="filter-pagination  mt-3">
                    {[
                        {label: "<<", page: 1, className: "previous-button"},
                        {label: "Previous", page: currentPage - 1, className: "previous-button"},
                        {label: "Next", page: currentPage + 1, className: "next-button"},
                        {label: ">>", page: totalPages, className: "next-button"},
                    ].map(({label, page, className}) => (
                        <button
                            key={label}
                            className={className}
                            onClick={() => onPageChange(page)}
                            disabled={
                                isDisabled ||
                                (label === "Previous" && currentPage === 1) ||
                                (label === "Next" && currentPage === totalPages) ||
                                (label === "<<" && currentPage === 1) ||
                                (label === ">>" && currentPage === totalPages)
                            }
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};
