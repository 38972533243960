import React, {useEffect, useState, useRef} from "react";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";
import {discounts_column_name} from "../../../../components/FilteringTable/Columns";
import {useHistory} from "react-router-dom";
import DatePicker from "react-datepicker";
import {compareDesc, isPast} from 'date-fns'
import {
    addDiscountForCompany,
    deleteDiscountById,
    getDiscountDetailById,
    updateDiscountCall,
    getDiscountsByGroupId, duplicateDiscountCall, patchDiscountCall
} from "../../../../Api/ApiCalls";
import {handleError, getDiscountStatus} from "../../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import AddVoucherModal from "../../../../components/Actions/Vouchers/AddVoucherModal";
import UploadVoucherModal from "../../../../components/Actions/Vouchers/UploadVoucherModal";
import swal from 'sweetalert'

import 'react-tabs/style/react-tabs.css';
import { DiscountType } from "../../../../config/Constants";
import equal from "fast-deep-equal";
import PaginationControls from "../../../../components/PaginationControls";
import DiscountModal from "../../../../components/Actions/DiscountModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function  Discounts ({companyId,groups, groupId, groupName, isSendStatistics}) {
    const initialFormData = {
        averageSaving: 0.00,
        amountOfCoupons: 0,
        categoryId: 0,
        price: 0,
        transactionFee: 0,
        minPurchasableVouchers: 0,
        maxPurchasableVouchers: 1,
        companyId: companyId,
        startDate: "",
        endDate: "",
        autoStart: true,
        isGeneralStudentDiscount: false,
        isUnLimited: false,
        canGenerateQrCode: false,
        qrCodeImage: null,
        externalUrl: "",
        groupId: groupId,
        localizations: [
            {
                lang: "nl",
                title: "",
                description: "",
                termsNConditions: "",
            },
            {
                lang: "en",
                title: "",
                description: "",
                termsNConditions: "",
            },
        ],
        usageLimitation: "once_day",
    }
    const [discounts, setDiscounts] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    const [discountType, setDiscountType] = useState(DiscountType.COUPONS);
    const [showModal, setShowModal] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [pageSize, setPageSize] = useState(25); // Default page size
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const [columns, setColumns] = useState([]);
    const [showCouponsModal, setShowCouponsModal] = useState(false);
    const [showUploadCouponsModal, setShowUploadCouponsModal] = useState(false);
    const [discountId, setDiscountId] = useState(0);
    const [unfilteredDiscounts, setUnfilteredDiscounts] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [formImageData, setFormImageData] = useState({qrCodeImage: null});
    const [prevCopounEndDate, setPrevCopounEndDate] = useState()
    const serverDiscountStateRef = useRef([]);
    const initialDiscountFormState = [initialFormData, DiscountType.COUPONS]
    const discountFormState = [formData, discountType]
    const [editingState, setEditingState] = useState({
        editingCell: null,
        tempValues: {},
        dateValues: {},
    });

    useEffect(() => {
        setColumns(discounts_column_name);
    }, []);

    useEffect(() => {
        getDiscounts(currentPage, companyId, groupId).then( );
    }, [pageSize]);

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            isUnLimited: discountType === DiscountType.EXTERNAL_COUPON || prev.usageDetails?.totalCapacity === -1,
            amountOfCoupons: discountType === DiscountType.COUPONS ? prev.usageDetails?.totalCapacity ?? 0 : -1
        }));
        
    }, [discountType]);


    const tableInstance = useTable(
        {columns, data: discounts, initialState: {pageIndex: 0,  pageSize: pageSize }},
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page} = tableInstance;


    // Handle Page Change
    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setEditingState((prev) => ({ ...prev, editingCell: null, dateValues: {} }));
        setCurrentPage(page);
        getDiscounts(page, companyId, groupId).then();
    };

    // Handle Page Size Change
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleEdit = (rowId, columnId) => {
        setEditingState((prev) => ({ ...prev, editingCell: { rowId, columnId } }));
    };

    const handleChange = (value, row, columnId, type = "text") => {

        if (type === "date" && isNaN(new Date(value).getTime())) {
            toast.error("Invalid date format");
            return;
        }

        if(type === "number" && value < -1) {
            toast.error(`The Total value cannot be less than -1. Resetting input.`);
            return;
        }

        setEditingState((prev) => ({
            ...prev,
            [type === "date" ? "dateValues" : "tempValues"]: {
                ...prev[type === "date" ? "dateValues" : "tempValues"],
                [row.id]: {
                    ...prev[type === "date" ? "dateValues" : "tempValues"][row.id],
                    [columnId]: value,
                }
            },
        }));
    };
    // Save changes (Blur or Enter)
    const handleBlur = async (row, columnId) => {
        const newValue = editingState.dateValues[row.id]?.[columnId];

        if (!editingState.editingCell || !newValue) {
            resetEditingCell()
            return;
        }
        const formattedNewValue = parseDate(newValue, columnId);
        const existingValue = row.original[columnId] ? new Date(row.original[columnId]) : null;

        if (existingValue && formattedNewValue.getTime() === existingValue.getTime()) {
            console.log("No changes detected, skipping API call.");
            resetEditingCell();
            return;
        }
        try {
            const updatedDiscount = { ...row.original, [columnId]: newValue };
            if (["startDate", "endDate"].includes(columnId)) {
                updatedDiscount[columnId] = new Date(newValue);
            }
            if (!validateDates(updatedDiscount.startDate, updatedDiscount.endDate)) {
                resetInvalidValue(row, columnId);
                return;
            }

            const dataToUpdate = {
                startDate: updatedDiscount.startDate,
                endDate: updatedDiscount.endDate,
            }
            await patchDiscountCall(dataToUpdate, companyId,updatedDiscount.id);

            toast.success(`Discount with Id: ${updatedDiscount.id}  Updated successfully`);
            setDiscounts((prevData) => {
                return prevData.map((item) =>
                    item.id === row.original.id ? { ...item, [columnId]: newValue } : item
                );
            });
        } catch (error) {
            handleError(error);
        }
        finally {
            resetEditingCell()
        }
    };

    const handleNumberBlur = async (row, columnId) => {
        if (!editingState.editingCell) return;

        try {
            const updatedValue   = Number(editingState.tempValues[row.id]?.[columnId]);
            const previousValue = getNestedValue(
                discounts.find((item) => item.id === row.original.id),
                columnId
            );
            if (updatedValue === previousValue || isNaN(updatedValue)) {
                console.log("No changes detected, skipping API call.");
                resetEditingCell()
                return;
            }

            const updateData = columnId
                .split(".")
                .reverse()
                .reduce((acc, key) => ({ [key]: acc }), updatedValue);

            const dataToUpdate = {
                amountOfCoupons: Number(updateData.usageDetails?.totalCapacity)
            }
            await patchDiscountCall( dataToUpdate, companyId, row.original.id);

            setDiscounts((prev) =>
                prev.map((item) => {
                    if (item.id !== row.original.id) return item; // Skip non-matching items
                    const usedCoupons =  row.original.usageDetails?.totalUsage ?? 0;
                    const remainingCoupons = (updatedValue=== -1) ? -1 : updatedValue - usedCoupons;
                    const updatedItem = {
                        ...item,
                        amountOfCoupons: columnId === "usageDetails.totalCapacity" ? updatedValue : item.amountOfCoupons,
                        usageDetails: {
                            ...item.usageDetails,
                            totalRemaining: remainingCoupons,
                        },
                    };

                    return setNestedValue(updatedItem, columnId, updatedValue);
                })
            );

            toast.success(`Discount ID: ${row.original.id} updated successfully`);
        } catch (error) {
            handleError(error);
        } finally {
            setEditingState((prev) => ({ ...prev, editingCell: null, tempValues: { ...prev.tempValues, [row.id]: {} } }));
        }
    };
    const handleKeyDown = (e, row, columnId) => {
        if (e.key === "Enter") {
            handleNumberBlur(row, columnId).then();
        }
    };

    const parseDate = (value, columnId) => {
        return ["startDate", "endDate"].includes(columnId) ? new Date(value) : value;
    };
    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, key) => acc?.[key], obj);
    };
    const setNestedValue = (obj, path, value) => {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const deepClone = { ...obj };

        let ref = deepClone;
        for (const key of keys) {
            ref[key] = { ...ref[key] }; // Ensure immutability
            ref = ref[key];
        }
        ref[lastKey] = value; // Set new value

        return deepClone;
    };

    const resetInvalidValue = (row, columnId) => {
        setEditingState((prev) => ({
            ...prev,
            editingCell: null,
            dateValues: {
                ...prev.dateValues,
                [row.id]: {
                    ...prev.dateValues[row.id],
                    [columnId]: row.original[columnId] || ""
                }
            },
        }));
    };
    const resetEditingCell = () => {
        setEditingState((prev) => ({ ...prev, editingCell: null }));
    };
    return (
        <>
            <div className="col-xl-12 col-xxl-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{groupName} - Discounts</h4>
                        <a className="btn btn-secondary" onClick={() => openModalAdd()}>+ Add discount</a>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <div className={`d-flex mb-2 w-100`} style={{}}>
                                {
                                    unfilteredDiscounts.length > 0 && (
                                        <div className={`d-flex mb-2 align-items-center justify-content-start flex-grow-1`}>
                                            <div className={ "actions-dropdown " + ( showMore ? 'disabled' : '' )}>
                                                <div className="btn btn-secondary" onClick={ (e) => { showPreviousDiscounts(true) }  }>
                                                    Show previous discounts
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                {headerGroups.map((headerGroup, i) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                                        {headerGroup.headers.map((column) => (
                                            <th key={`${column.id}-${column.Header}`}>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} key={i}>
                                            {row.cells.map((cell, index) => {
                                                const isEditable = cell.column.id === "startDate" || cell.column.id === "endDate" ;
                                                const isOptionsColumn = cell.column.Header === "Options";

                                                const isEditing = editingState.editingCell?.rowId === row.id && editingState.editingCell?.columnId === cell.column.id;

                                                const isDateColumn = ["startDate", "endDate"].includes(cell.column.id);
                                                const isNumberColumn = row.original.type === DiscountType.COUPONS && cell.column.id === "usageDetails.totalCapacity";
                                                const isDateEdit = isEditing && isDateColumn;
                                                const isNumberEdit = isEditing && isNumberColumn;
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        key={`${row.original.id}-${index}`}
                                                        className={`cursor-pointer ${isEditable || isNumberColumn ? "editable-cell" : ""}`}

                                                        onClick={() => (cell.column.Header === "Options" || isEditable || isNumberColumn)?   handleEdit(row.id, cell.column.id) : openModalEdit(row.original)}>
                                                        {isOptionsColumn ? (
                                                            <div className="actions-dropdown">
                                                                <div className="btn btn-primary">
                                                                    Options
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{width:"10px",marginLeft:"3px"}}><path fill="white" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                                                </div>
                                                                <ul>
                                                                    <li onClick={() => duplicateDiscount(row.original)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 464H288c8.8 0 16-7.2 16-16V384h48v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h64v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM224 304H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H224c-8.8 0-16 7.2-16 16V288c0 8.8 7.2 16 16 16zm-64-16V64c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64z"/></svg>
                                                                        Duplicate discount
                                                                    </li>
                                                                    <li  onClick={() => deleteDiscount(row.original.id)}>
                                                                        <FontAwesomeIcon icon={faTrash} style={{ color: "red", fontSize: "13px" }} />
                                                                        Delete
                                                                    </li>
                                                                    {row.original.type === 'VOUCHERS' && (
                                                                        <>
                                                                            <li onClick={() => UploadCoupon(row.original)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
                                                                                Upload Vouchers
                                                                            </li>
                                                                            <li onClick={() => history.push("/actions/vouchers/" + row.original.id)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg>
                                                                                Show Vouchers
                                                                            </li>
                                                                        </>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            ) : isDateEdit   ? (
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={editingState.dateValues[row.id]?.[cell.column.id]
                                                                        ? new Date(editingState.dateValues[row.id][cell.column.id])
                                                                        : row.original[cell.column.id]
                                                                            ? new Date(row.original[cell.column.id])
                                                                            : null}
                                                                    onChange={(date) => handleChange(date, row, cell.column.id, 'date')}
                                                                    onBlur={() => handleBlur(row, cell.column.id)}
                                                                    autoFocus
                                                                />
                                                            )  : isNumberEdit ? (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={editingState.tempValues[row.id]?.[cell.column.id] ?? getNestedValue(row.original, cell.column.id) ?? ""}
                                                                    onChange={(e) => handleChange(e.target.value, row, cell.column.id, "number")}
                                                                    onBlur={() => handleNumberBlur(row, cell.column.id)}
                                                                    onKeyDown={(e) => handleKeyDown(e, row, cell.column.id)}
                                                                    autoFocus
                                                                    min="-1"
                                                                />
                                                        ) : (
                                                            cell.render("Cell")
                                                        )}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>

                            {/* Pagination Component */}
                            <PaginationControls
                                currentPage={currentPage}
                                totalPages={totalPages}
                                pageSize={pageSize}
                                onPageChange={handlePageChange}
                                onPageSizeChange={handlePageSizeChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <DiscountModal
                show={showModal}
                showEdit={showEdit}
                onClose={confirmModal}
                formData={formData}
                setFormData={setFormData}
                discountType={discountType}
                setDiscountType={setDiscountType}
                groups={groups}
                setFormImageData={setFormImageData}
                formImageData={formImageData}
                handleSubmit={showEdit ? updateDiscount : addDiscount}
                onDelete={() => deleteDiscount(formData.id)}
            />

            <AddVoucherModal showCouponsModal={showCouponsModal} setShowCouponsModal={setShowCouponsModal} actionId={discountId} ></AddVoucherModal>
            <UploadVoucherModal showUploadCouponsModal={showUploadCouponsModal} setShowUploadCouponsModal={setShowUploadCouponsModal} actionId={discountId} ></UploadVoucherModal>

        </>
    );

    async function showPreviousDiscounts(showMore)
    {
        const Items = Object.assign([], unfilteredDiscounts);
        setDiscounts(Items)
        setShowMore(showMore)
    }

    async function UploadCoupon(discount)
    {
        setShowUploadCouponsModal(true)
        setDiscountId(discount.id)
    }

    async function getDiscounts(pageIndex, companyId, groupId, show = false) {
        try {
            const result = await getDiscountsByGroupId(pageIndex, pageSize, companyId, groupId);

            let items = result.items

            setUnfilteredDiscounts(items)

            if(!show)
                items = items.filter( (item) =>  getDiscountStatus(item.startDate, item.endDate) !== 'Expired')

            setDiscounts(items);
            setTotalPages(result.totalPages);
            setCurrentPage(result.page);
        } catch (err) {
            console.log(err);
            handleError(err);
        }
    }

    async function openModalAdd() {
        setDiscountType(DiscountType.COUPONS)
        setFormImageData({qrCodeImage: null})
        setFormData(initialFormData)
        setShowModal(true);
        setShowEdit(false);
    }

    async function openModalEdit(element) {
        const result = await getDiscountDetailById(companyId, element.id);
        setShowEdit(true);
        setShowModal(true);
        const discountDetails = {
            autoStart: result.autoStart,
            isGeneralStudentDiscount: result.isGeneralStudentDiscount,
            id: result.id,
            isUnLimited: result?.amountOfCoupons === -1,
            averageSaving: result.averageSaving,
            minPurchasableVouchers: result.minPurchasableVouchers,
            maxPurchasableVouchers: result.maxPurchasableVouchers,
            price: result.price,
            transactionFee: result.transactionFee,
            amountOfCoupons: result.amountOfCoupons,
            canGenerateQrCode: result.canGenerateQrCode,
            companyId: companyId,
            startDate: result.startDate,
            endDate: result.endDate,
            usageLimitation: result.usageLimitation,
            usageDetails: result.usageDetails,
            externalUrl: result.externalUrl,
            groupId: result.groupId,
            localizations: [
                {
                    lang: "nl",
                    title: result.localization?.nl?.subtitle,
                    description: result.localization?.nl?.description,
                    termsNConditions: result.localization?.nl?.termsNConditions,
                },
                {
                    lang: "en",
                    title: result.localization?.en?.subtitle,
                    description: result.localization?.en?.description,
                    termsNConditions: result.localization?.en?.termsNConditions,
                },

            ],
        }
        setFormData(discountDetails);
        setFormImageData({qrCodeImage: result.qrCodeUrl})
        setDiscountType(result.type)
        setPrevCopounEndDate(result.endDate)
        serverDiscountStateRef.current = [discountDetails, result.type]
    }


    async function updateDiscount() {
        try {
            let formDataWithoutId = {...formData};
            delete formDataWithoutId.id;

            // Validate Min/Max Purchasable Vouchers
            if (!validatePurchasableVouchers(formData)) return;

            // Validate Start & End Date
            if (!validateDates(formData.startDate, formData.endDate)) return;

            // manually end discount options
            if(isSendStatistics && !isPast(new Date(prevCopounEndDate)) && isPast(new Date(formData.endDate))){ 
                const isConfirmed = await swal({
                    title: "Are you sure?",
                    text: "Do you want a report with the results from this coupon promotion to be sent to the partner?",
                    icon: "warning",
                    buttons: {
                        cancel: true,
                        no :{
                            text: "No",
                            value: "no",
                            className: "swal-button--danger"
                        },
                        yes : {
                            text: "Yes",
                            value: "yes",
                            className: "swal-button--info"
                        },
                    },
                  }).then((value) => {
                    switch (value) {
                        case "yes":
                            formDataWithoutId.isSendManuallyEndedDiscountReport = true
                            return true;
                        case "no":
                            formDataWithoutId.isSendManuallyEndedDiscountReport = false
                            return true;
                        default:
                            return false;
                    }
                  })
             
                if(!isConfirmed) return false
            }
          
            if (discountType !== DiscountType.EXTERNAL_COUPON){
                delete formDataWithoutId.externalUrl;
            }
            const formDataToSend = getDiscountDataToSend(formDataWithoutId)
            const result = await updateDiscountCall(formDataToSend, companyId, formData.id); // Sending formDataToSend to the API function
            setDiscounts((prevData) => {
                return prevData.map((item) => {
                    if (item?.id === result?.id) {
                        return {
                            ...item,
                            id: result.id,
                            averageSaving: result.averageSaving,
                            amountOfCoupons: result.amountOfCoupons,
                            isUnLimited: result.isUnLimited,
                            companyId: companyId,
                            startDate: result.startDate,
                            endDate: result.endDate,
                            usageLimitation: result.usageLimitation,
                            minPurchasableVouchers: result.minPurchasableVouchers,
                            maxPurchasableVouchers: result.maxPurchasableVouchers,
                            price: result.price,
                            localizations: [
                                {
                                    lang: "en",
                                    title: result.localization.en.subtitle,
                                    description: result.localization.en.description,
                                    termsNConditions: result.localization.en.termsNConditions,
                                },
                                {
                                    lang: "nl",
                                    title: result.localization.nl.subtitle,
                                    description: result.localization.nl.description,
                                    termsNConditions: result.localization.nl.termsNConditions,
                                },
                            ],
                        };
                    }
                    return item;
                });
            });
            setDiscountType(result.type)

            toast.success("Discount Updated successfully");
            await getDiscounts(currentPage, companyId, groupId);
            setShowModal(false);
        } catch (err) {
            handleError(err);
        }
    }


    async function addDiscount() {
        try {
            // Validate Min/Max Purchasable Vouchers
            if (!validatePurchasableVouchers(formData)) return;

            // Validate Start & End Date
            if (!validateDates(formData.startDate, formData.endDate)) return;


            let formDataToSend = getDiscountDataToSend(formData)

            await addDiscountForCompany(formDataToSend, companyId); // Sending formDataToSend to the API function
            toast.success("Discount Added successfully");
            await getDiscounts(currentPage, companyId, groupId);
            setShowModal(false);

        } catch (err) {
            handleError(err);
        }
    };

    async function duplicateDiscount(data) {
        try {
            await duplicateDiscountCall(data.id)
            await getDiscounts(currentPage, companyId, groupId);
            toast.success("Discount duplicated successfully!");
        } catch (err) {
            handleError(err);
        }
    }

    function getDiscountDataToSend(formData) {
        const formDataToSend = new FormData();

        // Process Localizations
        if (formData.localizations) {
            const localizationsArray = formData.localizations.map(({ lang, title, description, termsNConditions }) => ({
                lang,
                title,
                description,
                ...(discountType === DiscountType.VOUCHERS && { termsNConditions }),
            }));
            formDataToSend.append("localizations", JSON.stringify(localizationsArray));
        }


        // Process Other Fields
        Object.entries(formData).forEach(([key, value]) => {
            if (key === "localizations" || key === "backgroundImage") return; // Skip already handled or unnecessary fields

            if (["startDate", "endDate"].includes(key)) {
                let newDate = new Date(formData[key])
                newDate = newDate.getFullYear() + '-' + ( newDate.getMonth() + 1 ) + '-' + newDate.getDate() + ' GMT';
                formDataToSend.append(key, newDate);
            } else {
                formDataToSend.append(key, value);
            }
        });

        const amountOfCoupons = formData.isUnLimited ? -1 : formData.amountOfCoupons;
        formDataToSend.set("amountOfCoupons", amountOfCoupons);

        if (discountType !== DiscountType.EXTERNAL_COUPON)
            formDataToSend.delete('externalUrl');

        formDataToSend.append('type', discountType)
        formDataToSend.set('localizations', JSON.stringify(formDataToSend.get('localizations')))
        return formDataToSend;
    }
    function validatePurchasableVouchers(formData) {
        if (Number(formData.minPurchasableVouchers) > Number(formData.maxPurchasableVouchers)) {
            toast.error("Min Purchasable Vouchers can't be more than Max Purchasable coupons!");
            return false;
        }
        return true;
    }
    function validateDates(startDate, endDate) {
        if (compareDesc(new Date(startDate), new Date(endDate)) === -1) {
            toast.error("Start Date can't be after End Date!");
            return false;
        }
        return true;
    }
    async function deleteDiscount(discountId) {
        try {
            await deleteDiscountById(companyId, discountId);
            setDiscounts((prevDiscounts) =>
                prevDiscounts.filter((discount) => discount.id !== discountId)
            );
            toast.success("Discount Deleted successfully");
        } catch (err) {
            handleError(err);
        }
        finally {
            setShowModal(false);
            setShowEdit(false);
        }
    }

    function confirmModal(){
        if(!showEdit && equal(initialDiscountFormState, discountFormState)) {
            setShowModal(false)
            return
        }

        if(showEdit && equal(serverDiscountStateRef.current, discountFormState)) {
            setShowModal(false)
            return
        }

        swal({
            title: "Are you sure?",
            text: "You want to discard your changes?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((response) => {
            if (response) {
                setShowModal(false)
            }
          });
    }
};
