import React from "react";
import { Card } from "react-bootstrap";

const StatCard = ({ value, label }) => {
    return (
        <Card className="p-4">
            <h2 className="text-dark font-weight-bold">{value}</h2>
            <p className="text-muted">{label}</p>
        </Card>
    );
};

export default StatCard;
