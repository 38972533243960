import React, {Fragment, useState} from 'react';
import {fetchKnaekIdCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../../config/Constants";
import { handleError } from '../../../utils/SharedFunctions';
import { transformDate } from '../Users/helpers';

export default function KnaekIdSearch() {

    const [knaekId, setKnaekId] = useState([])
    const [knaekIdData, setKnaekIdData] = useState([])

    return (
        <>
            <Fragment>
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Knaek-ID</h4>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-start align-content-center align-items-center" style={{width:"60%",gap:"40px"}}>
                                    <div className="" >
                                        <label className="text-label">
                                        Knaek-ID:
                                        </label>
                                        <input
                                            name="title"
                                            className="form-control mb-3"
                                            style={{width: "300px", textTransform: "uppercase" }}
                                            onChange={val => {
                                                setKnaekId(val.target.value.toUpperCase())
                                            }}
                                        />
                                    </div>
                                    <div className="" style={{marginTop:"12px"}}>
                                        <button className="btn btn-secondary sw-btn-next ms-1"
                                                onClick={() => fetchKnaekId()}>
                                            Search
                                        </button>
                                    </div>
                                </div>

                                <table className="table dataTable display">
                                    <thead>
                                    <tr>
                                        <th>
                                            <span className="ml-1">
                                                Created At
                                            </span>
                                        </th>
                                        <th>
                                            <span className="ml-1">
                                                Email
                                            </span>
                                        </th>
                                        <th>
                                            <span className="ml-1">
                                                Expiration Date
                                            </span>
                                        </th>
                                        <th>
                                            <span className="ml-1">
                                                Redeem Before Date
                                            </span>
                                        </th>
                                        <th>
                                            <span className="ml-1">
                                                Is Discount Code
                                            </span>
                                        </th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="cursor-pointer">
                                            {transformDate(knaekIdData.createdDate,false)}
                                        </td>
                                        <td className="cursor-pointer">
                                            {knaekIdData.email ?? '-'}
                                        </td>
                                        <td className="cursor-pointer">
                                            {transformDate(knaekIdData.expirationDate,true)}
                                        </td>
                                        <td className="cursor-pointer">
                                            {transformDate(knaekIdData.redeemBeforeDate,true)}
                                        </td>
                                        <td className="cursor-pointer">
                                            {knaekIdData.isDiscountCode ? "Yes" : "No"}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )

    function fetchKnaekId() {
        fetchKnaekIdCall(knaekId).then(result => {
            if (!result) {
                toast.error("Knaek-ID not found", toastOptions)
            } else {
                toast.success("Knaek-ID found", toastOptions)
            }
            setKnaekIdData(result)
        }).catch(error => {
            handleError(error)
        })
    }
}
