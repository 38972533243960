import React, {useEffect, useState} from "react";
import {Row, Col, Spinner} from "react-bootstrap";
import StatCard from "./components/StatCard";
import DiscountUsageChart from "./components/DiscountUsageChart";
import {getDiscountUsageStatistics, getUsersStatistics} from "../../../Api/ApiCalls";


const Stats = () => {
    const [discountUsagedata, setDiscountUsagedata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState({
        totalUsers: 0,
        unlimitedMembers: 0,
        activeMembers: 0
    });

    useEffect( () => {
         fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {

            const [statsResponse, discountData] = await Promise.all([
                getUsersStatistics(),
                getDiscountUsageStatistics()
            ]);

            setStats({
                totalUsers: statsResponse.totalUsers,
                unlimitedMembers: statsResponse.unlimitedMembers,
                activeMembers: statsResponse.activeMembers,
            });

            setDiscountUsagedata(discountData );
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            setLoading(false);
        }

    };

    return (
        <Row className="gy-4">
            {loading ? (
                <div className="text-center mt-4">
                    <Spinner animation="border" />
                </div>
            ) : (

           <>
               {/* Stats Cards */}
                <Col md={4}><StatCard value={stats.totalUsers} label="Total Users (NL & BE)" /></Col>
                <Col md={4}><StatCard value={stats.unlimitedMembers} label="Unlimited Users (NL & BE)" /></Col>
                <Col md={4}><StatCard value={stats.activeMembers} label="Active Members (NL & BE)" /></Col>

                {/* Chart Section (Takes Full Width) */}
                <Col md={12}>

                    <DiscountUsageChart data={discountUsagedata} />

                </Col>
           </>
                )}
        </Row>
    );
};

export default Stats;
