import React, {useEffect, useState} from "react";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";
import {Modal} from "react-bootstrap";
import {locations_column_name} from "../../../../components/FilteringTable/Columns";
import {
  createLocationForCompany,
  deleteCompanyLocation, getCoordinates,
  getLocationsByCompanyId,
  updateLocationCall,
} from "../../../../Api/ApiCalls";
import {handleError} from "../../../../utils/SharedFunctions";
import {ApiSearchField} from "../../../../components/ApiSearchField";
import {toast} from "react-toastify";
import {MoreInfo} from "../../../../components/MoreInfo";
import { TooltipMessagesTypes } from '../../../../enums/tooltipMessagesTypes';
import PaginationControls from "../../../../components/PaginationControls";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function Locations({companyId}) {
  const [isLoading, setIsLoading] = useState(false);

  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [locations, setLocations] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [targetPage, setTargetPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [initialValues, setInitialValues] = useState({
    address: "",
    city: "",
    locationId: "",
    postalCode: "",
    latitude: "",
    longitude: "",
  });


  const LocationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    city: Yup.string().required("City is required"),
    latitude: Yup.number()
        .typeError("Latitude must be a number")
        .required("Latitude is required"),
    longitude: Yup.number()
        .typeError("Longitude must be a number")
        .required("Longitude is required")
  });
  useEffect(() => {
    setColumns(locations_column_name);
  }, []);


  useEffect(() => {
    getLocations(1, companyId);
  }, [debouncedSearchQuery, companyId]);



  const tableInstance = useTable(
    {
      columns,
      data: locations,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page
  } = tableInstance;

  const fetchCoordinates = async (values, setFieldValue) => {
    console.log("Fetching coordinates...");

    const address = `${values.address}, ${values.city}, ${values.postalCode}`;

    try {
      const response = await getCoordinates(address);
      if (response) {
        const lat = response.latitude.toFixed(5);
        const lng = response.longitude.toFixed(5);

        // Update Formik fields
        setFieldValue("latitude", lat);
        setFieldValue("longitude", lng);
      }
    } catch (error) {
      handleError(error);
      console.error("Failed to retrieve coordinates:", error);
    }
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    getLocations(page, companyId).then();
  };
  return (
    <>
      <div className="col-xl-12 col-xxl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Locations</h4>
            <a className="btn btn-secondary" onClick={() => openModalAdd()}>
              + Add location
            </a>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className={`d-flex flex-column mb-2 w-25`} style={{}}>
                <ApiSearchField
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
              </div>
              {" "}
              <table {...getTableProps()} className="table dataTable display">
                <thead>
                {headerGroups.map((headerGroup, i) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, index) => (
                          <th key={index}>
                            {column.render("Header")}
                          </th>
                      ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, index) => {
                          return (
                              <td
                                  {...cell.getCellProps()}
                                  key={index}
                                  className={"cursor-pointer"}
                                  onClick={() => openModalEdit(row.original)}>
                                {cell.render("Cell")}
                              </td>
                          );
                        })}
                      </tr>
                  );
                })}
                </tbody>
              </table>
              {/* Pagination Component */}
              <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>


      <Modal
          className="modal fade"
          size="xl"
          show={showModal}
          onHide={setShowModal}>
        <div role="document">
          <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={LocationSchema}
              onSubmit={(values) => {
                showEdit ? updateLocation(values) : addLocation(values);
              }}
          >
            {({ values, isSubmitting, setFieldValue }) => {
              const isRetrieveEnabled =
                  values.address.trim() !== "" &&
                  values.city.trim() !== "" &&
                  values.postalCode.trim() !== "";

              return (
                  <Form>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20"> {showEdit ? "Update Location" : "Add Location"} </h4>
                      <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowModal(false)}
                          data-dismiss="modal">
                      </button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="row">
                            {/* Address */}
                            <div className="form-group mb-3 col-sm-10 col-md-6">
                              <label className="text-black font-w500">Address*</label>
                              <Field type="text" name="address" className="form-control" />
                              <ErrorMessage name="address" component="div" className="text-danger" />
                            </div>
                            {/* Postal Code Field */}
                            <div className="form-group mb-3 col-sm-10 col-md-6">
                              <label className="text-black font-w500">Postal Code*</label>
                              <Field type="text" name="postalCode" className="form-control" />
                              <ErrorMessage name="postalCode" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="row">
                            {/* City Field */}
                            <div className="form-group mb-3 col-sm-10 col-md-6">
                              <label className="text-black font-w500">City*</label>
                              <Field type="text" name="city" className="form-control" />
                              <ErrorMessage name="city" component="div" className="text-danger" />
                            </div>
                            <div className="form-group mb-3 col-sm-10 col-md-6 d-flex flex-column justify-content-center mt-3">
                              <label className="text-black font-w500 mt-1"></label>
                              <a
                                  onClick={(event) => {
                                    if (!isRetrieveEnabled) {
                                      event.preventDefault(); // Prevent clicking when disabled
                                      return;
                                    }
                                    fetchCoordinates(values, setFieldValue)
                                  }}
                                  style={{
                                    color: isRetrieveEnabled ? "#D44F9E" : "gray",
                                    cursor: isRetrieveEnabled ? "pointer" : "not-allowed",
                                    textDecoration: isRetrieveEnabled ? "underline" : "none"
                                  }}
                              >
                                Retrieve Coordinates (click)
                              </a>


                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-sm-10 col-md-6">
                              <label className="text-black font-w500 d-flex align-items-center">
                                Latitude*
                                <MoreInfo
                                    content={[TooltipMessagesTypes.LATITUDE_EXAMPLE, TooltipMessagesTypes.ONLY_NUMBERS_NO_CHARACHTERS_SYMBOLS]}/>
                              </label>
                              <Field type="number" name="latitude" className="form-control" placeholder="51.59167"/>
                              <ErrorMessage name="latitude" component="div" className="text-danger"/>
                            </div>
                            <div className="form-group mb-3 col-sm-10 col-md-6">
                              <label className="text-black font-w500 d-flex align-items-center">
                                Longitude*
                                <MoreInfo
                                    content={[TooltipMessagesTypes.LONGTITUDE_EXAMPLE, TooltipMessagesTypes.ONLY_NUMBERS_NO_CHARACHTERS_SYMBOLS]}/>
                              </label>
                              <Field type="number" name="longitude" className="form-control" placeholder="4.78462" />
                              <ErrorMessage name="longitude" component="div" className="text-danger" />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    {!showEdit && (
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-secondary" disabled={isSubmitting}>
                            Add
                          </button>
                          <button className="btn btn-warning" onClick={() => setShowModal(false)}>
                            Discard
                          </button>
                        </div>
                    )}

                    {showEdit && (
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-secondary" disabled={isSubmitting}>
                            Save
                          </button>
                          <button className="btn btn-warning" onClick={() => setShowModal(false)}>
                            Cancel
                          </button>
                          <button className={`btn btn-danger ${isLoading ? 'disabled' : ''}`} onClick={() => deleteLocation()}>
                            Delete
                          </button>
                        </div>
                    )}
                  </Form>
            );
            }}
          </Formik>
        </div>
      </Modal>

    </>
  );

  async function openModalAdd() {
    resetLocationData()
    setShowModal(true);
    setShowEdit(false);
  }

  async function openModalEdit(element) {
    setShowModal(true);
    setShowEdit(true);
    setInitialValues({
      address: element.address,
      city: element.city,
      postalCode: element.postalCode,
      locationId: element.id,
      longitude: element?.coordinate?.longitude || null,
      latitude: element?.coordinate?.latitude || null,
    });
  }

  async function getLocations(pageIndex, companyId) {
    try {
      const result = await getLocationsByCompanyId(pageIndex, 10, companyId, debouncedSearchQuery);
      setLocations(result.items);
      setTotalPages(result.totalPages);
      setCurrentPage(result.page);
      setTargetPage(result.page + 1);
    } catch (err) {
      console.error("Failed to fetch locations:", err);
      handleError(err);
    }
  }

  async function addLocation(values) {
    setIsLoading(true);
    try {
      const dataToSend = {
        ...values,
        companyId: parseInt(companyId),
        latitude: parseFloat(values.latitude) || 0,
        longitude: parseFloat(values.longitude) || 0,
      };
      const result = await createLocationForCompany(dataToSend);
      toast.success("Location Created successfully");
      setLocations(prev => [...prev, result]);
      await getLocations(currentPage, companyId);
      setShowModal(false);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateLocation(values) {
    setIsLoading(true);

    try {
      const dataToSend = {
        city: values.city,
        postalCode: values.postalCode,
        address: values.address,
        latitude: parseFloat(values.latitude) || 0,
        longitude: parseFloat(values.longitude) || 0,
      };
      const result = await updateLocationCall(dataToSend, values.locationId);
      setLocations((prevData) => {
        return prevData.map((item) => {
          if (item?.id === result?.id) {
            return {
              ...item,
              address: result?.address,
              city: result?.city,
              coordinate: {
                longitude: result?.coordinate?.longitude || 0,
                latitude: result?.coordinate?.latitude || 0,
              },
            };
          }
          return item;
        });
      });
      toast.success("Location Updated successfully");
      setShowModal(false);
      await getLocations(currentPage, companyId);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteLocation () {
    try {
      await deleteCompanyLocation(initialValues.locationId);
      await getLocations(currentPage, companyId);
      toast.success("Location Deleted successfully");
    } catch (err) {
      handleError(err);
    } finally {
      setShowModal(false);
      setShowEdit(false);
    }
  }


  function resetLocationData() {
    setInitialValues({
          address: "",
          city: "",
          locationId: "",
          postalCode: "",
          latitude: "",
          longitude: "",
        }
    )
  }

};
