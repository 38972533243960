import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Modal} from "react-bootstrap";
import {toastOptions} from "../../../../config/Constants";
import {
    fetchFilterProductionNotificationCall,
    createCompanyBulkFavoriteCall
} from "../../../../Api/ApiCalls";
import {hasAValue} from "../../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import 'react-tabs/style/react-tabs.css';
import { ButtonLoading } from "../../../../components/Common/ButtonLoading";
import {MoreInfo} from "../../../../components/MoreInfo";
import {TooltipMessagesTypes} from "../../../../enums/tooltipMessagesTypes";


export default function  FavouritModal ({companyId, showModal, setShowModal , onFavoriteAdded }) {

    const [filterLimited, setFilterLimited] = useState(undefined)
    const [filtersAccountOwners, setFiltersAccountOwners] = useState([])
    const [filterAccountOwner, setFilterAccountOwner] = useState(undefined)
    const [filtersCities, setFiltersCities] = useState([])
    const [selectedFiltersCities, setSelectedFiltersCities] = useState([])
    const [country, setCountry] = useState(1)
    const [isSubmitting, setisSubmitting] = useState(false);

    const isAccountOwnerEnabled = filterLimited !== 'limited';
    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
        const {
            children = <CustomClearText/>,
            getStyles,
            innerProps: {ref, ...restInnerProps},
        } = props;
        return (
            <div {...restInnerProps}
                 ref={ref}
                 style={getStyles("clearIndicator", props)}
            >
                <div style={{padding: "0px 5px"}}>{children}</div>
            </div>
        );
    };

    const ClearIndicatorStyles = (base, state) => ({
        ...base,
        cursor: "pointer",
        color: state.isFocused ? "blue" : "black",
    });
    useEffect(() => {
           fetchFilters();
    }, [])

    useEffect(() => {
        if (filterLimited === "limited") {
            setFilterAccountOwner(undefined);
        }
    }, [filterLimited]);

    const limitationTypes = [
        {label: "Limited & Unlimited", value: "limited_unlimited"},
        {label: "Limited", value: "limited"},
        {label: "Unlimited", value: "unlimited"},
        {label: "Limited plus", value: "limited Plus"}
    ]
    const resetForm = () => {
        setFilterLimited(undefined);
        setFilterAccountOwner(undefined);
        setSelectedFiltersCities([]);
        setCountry(1);
    };
    return (
        <>
            <Modal
                className="modal fade mt-5"
                size="xl"
                show={showModal}
            >
                <div role="document">
                      <form onSubmit={addFavourite}>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">
                                    Add Company To Favourite
                                </h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        resetForm();
                                        setShowModal(false);
                                    }}
                                    data-dismiss="modal">

                                </button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-10 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Country</label>
                                                    <select
                                                        className="form-control form-control-md"
                                                        defaultValue={hasAValue(country) && country}
                                                        required
                                                        onChange={(val) => {
                                                            setCountry(val.target.value)
                                                        }}
                                                    >
                                                        <option value={1}>Netherlands</option>
                                                        <option value={2}>Belgium</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-10 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Cities</label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={{ClearIndicator}}
                                                        styles={{clearIndicator: ClearIndicatorStyles}}
                                                        isMulti
                                                        defaultValue={selectedFiltersCities}
                                                        options={filtersCities}
                                                        onChange={(cities) => {
                                                            setSelectedFiltersCities(cities)
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-10 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Mode</label>
                                                    <select
                                                        className="form-control form-control-md"
                                                        value={filterLimited}
                                                        onChange={(val) => {
                                                            setFilterLimited(val.target.value ?? undefined)
                                                        }}>
                                                        <option value={''}>Choose an option</option>

                                                        {limitationTypes.map((item, i)=>{
                                                            return(
                                                                <option key={item.value} value={item.value}>{item.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-10 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label d-flex align-items-center ">
                                                        <span className="me-2">Account Owner</span>
                                                        <MoreInfo
                                                            content={[TooltipMessagesTypes.ACCOUNT_OWNER_MODE_NOTE]}/>
                                                    </label>
                                                    <select
                                                        disabled={!isAccountOwnerEnabled}
                                                        className='form-control form-control-md'
                                                        value={isAccountOwnerEnabled ? filterAccountOwner : ''} // Ensure reset when disabled
                                                        onChange={(e) => setFilterAccountOwner(e.target.value)}
                                                    >
                                                    <option value={''}>Choose an option</option>
                                                      {filtersAccountOwners.map((item, i) => {
                                                        return <option key={item.value} value={item.value}>{item.value?.replaceAll('_', ' ')}</option>;
                                                      })}
                                                  </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <p className="text-red">NOTE: {TooltipMessagesTypes.COMPANY_FAVOURITE_WARNING}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" type="submit" disabled={isSubmitting}>
                                   <ButtonLoading isLoading={isSubmitting} text='Add' />
                                </button>
                                <button className="btn btn-warning" type="button" onClick={() => {
                                    resetForm();
                                    setShowModal(false);
                                }}>
                                    Discard
                                </button>
                            </div>

                        </form>
                </div>
            </Modal>
        </>
    );
    
    function addFavourite(e){ //onSubmit
        e.preventDefault()

        const data = {
            'countryId' : parseInt(country),
            'cityIds' : selectedFiltersCities.map(city => city.value),
            'mode' : filterLimited,
            'accountOwner' : filterAccountOwner 
        }

        setisSubmitting(true)

        createCompanyBulkFavoriteCall(data, companyId).then(results => {
            toast.success("The Company were added to the users Favourites !", toastOptions);
            onFavoriteAdded(results.count);
            resetForm();
            setShowModal(false)
        }).catch(_ => {
            toast.error("An Error Occurred", toastOptions);
        }).finally(() => setisSubmitting(false));
    }

    function fetchFilters() {
        fetchFilterProductionNotificationCall().then(results => {
            setFiltersAccountOwners(results.accountOwners)
            const cities = results.cities.filter(item => parseInt(item.countryId) === parseInt(country))
            setFiltersCities(cities)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }


};