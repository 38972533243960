import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { toast } from "react-toastify";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { introduction_events_col } from "../../../components/FilteringTable/Columns";
import { GlobalFilter } from "../../../components/FilteringTable/GlobalFilter";
import {
  createIntroEventCall,
  getAllIntrosEventsCall,
  updateIntroEventCall,
} from "../../../Api/ApiCalls";
import { toastOptions } from "../../../config/Constants";
import { handleError } from "../../../utils/SharedFunctions";
import IntroEventQR from "../../../components/IntroEventQR";

export default function IntroductionEventsOverview() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [education_name, setEducation_name] = useState(undefined);
  const [country, setCountry] = useState('NL');
  const [slug, setSlug] = useState(undefined);
  const [isActive, setIsActive] = useState(false);
  const [item, setItem] = useState(undefined);

  const [contentTitle4, setContentTitle4] = useState(undefined);
  const [contentTitle4EN, setContentTitle4EN] = useState(undefined);
  const [contentText4, setContentText4] = useState(undefined);
  const [contentText4EN, setContentText4EN] = useState(undefined);
  const [contentButtonUrl4, setContentButtonUrl4] = useState(undefined);
  const [contentButtonLabel4, setContentButtonLabel4] = useState(undefined);
  const [showSection4, setShowSection4] = useState(true);
  const [showButton4, setShowButton4] = useState(true);

  useEffect(() => {
    setColumns(introduction_events_col);
    fetchAllIntros();
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Intro events</h4>
          <a className="btn btn-secondary" onClick={() => openModalAdd()}>
            + Add new introduction events
          </a>
        </div>
        {/*Table *****************/}
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table dataTable display">
              <thead>
                {headerGroups.map((headerGroup) => {
                  const {key:headerGroupPropsKey, ...headerGroupProps} = headerGroup.getHeaderGroupProps();
                  return (
                  <tr key={headerGroupPropsKey} {...headerGroupProps}>
                    {headerGroup.headers.map((column) => {
                      const {key:columnPropsKey, ...columnProps} = {...column.getHeaderProps(column.getSortByToggleProps())}

                      return (
                        column.Header === "Finished processes" ? (
                          <OverlayTrigger
                            placement="top"
                            offset={[0, 0]}
                            delay={{ show: 0, hide: 0 }}
                            overlay={
                              (props) => (
                              <Tooltip id="tooltip" {...props}>
                                  counted from August 1st each year
                              </Tooltip>
                            )}
                          >
                            <th key={columnPropsKey} {...columnProps}>
                              {column.render("Header")}
                            </th>
                        </OverlayTrigger>
                        ):(
                        <th key={columnPropsKey} {...columnProps}>
                            {column.render("Header")}
                        </th>
                       )
                    )})}
                  </tr>
                )})}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const {key:rowPropsKey, ...rowProps} = row.getRowProps();
                  return (
                    <tr key={rowPropsKey} {...rowProps}>
                      {row.cells.map((cell) => {
                        const {key:cellPropsKey, ...cellProps} = cell.getCellProps();
                        return (
                          <td
                            key={cellPropsKey}
                            {...cellProps}
                            className={"cursor-pointer"}
                            onClick={() => openModalEdit(row.original)}
                          >
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* This is only for footer if u require */}
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index d-none">
                Go to page :{" "}
                <input
                    type="number"
                    className="ml-2"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                      gotoPage(pageNumber);
                    }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Modal *****************/}
      <Modal
        className="modal fade"
        size="xl"
        show={showModal}
        onHide={setShowModal}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">New Introduction page</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  data-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                      <div className="row">
                        <IntroEventQR item={item} slug={slug} setSlug={setSlug} showEdit={showEdit} country={country}/>
                      </div>
                    <div className="row">
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Education name/student association
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required="required"
                            defaultValue={education_name}
                            onChange={(val) =>
                              setEducation_name(val.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Account owner</label>
                        <div className="contact-name">
                          <select
                              className="rounded form-control border-1"
                              name="user_type"
                              value={country}
                              onChange={(name) => {
                                setCountry((name).target.value)
                              }}
                          >
                            <option value={"NL"}>Knaek NL</option>
                            <option value={"BE"}>Knaek BE</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Is this active?
                          </label>
                          <p className="mb-1"></p>

                          <input
                              type="checkbox"
                              defaultValue={isActive}
                              checked={isActive}
                              className="form-check-input"
                              onChange={() => setIsActive(!isActive)}
                              required
                          />
                        </div>
                      </div>
                    </div>

                    {/*Fourth section *********************/}
                    <hr className="mb-5"></hr>
                    <h4 className="modal-title fs-20">Section</h4>
                    <div className="row">
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Is this active?
                          </label>
                          <p className="mb-1"></p>
                          <input
                            type="checkbox"
                            defaultValue={showSection4}
                            checked={showSection4}
                            className="form-check-input"
                            onChange={() => setShowSection4(!showSection4)}
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Is this button active?
                          </label>
                          <p className="mb-1"></p>
                          <input
                            type="checkbox"
                            defaultValue={showButton4}
                            checked={showButton4}
                            className="form-check-input"
                            onChange={() => setShowButton4(!showButton4)}
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Title NL</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required="required"
                            defaultValue={contentTitle4}
                            onChange={(val) =>
                              setContentTitle4(val.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Title EN</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required="required"
                            defaultValue={contentTitle4EN}
                            onChange={(val) =>
                              setContentTitle4EN(val.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Button label
                        </label>
                        <div className="contact-name">
                          <input
                            className="form-control"
                            name="name"
                            required="required"
                            defaultValue={contentButtonLabel4}
                            onChange={(val) =>
                              setContentButtonLabel4(val.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Button url
                        </label>
                        <div className="contact-name">
                          <input
                            className="form-control"
                            name="name"
                            required="required"
                            defaultValue={contentButtonUrl4}
                            onChange={(val) =>
                              setContentButtonUrl4(val.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-sm-10 col-md-12">
                        <label className="text-black font-w500">Text NL</label>
                        <div className="contact-name">
                          <textarea
                            className="form-control"
                            rows={4}
                            maxLength={200}
                            name="name"
                            required="required"
                            defaultValue={contentText4}
                            onChange={(val) =>
                              setContentText4(val.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-12">
                        <label className="text-black font-w500">Text EN</label>
                        <div className="contact-name">
                          <textarea
                            className="form-control"
                            rows={4}
                            maxLength={200}
                            name="name"
                            required="required"
                            defaultValue={contentText4EN}
                            onChange={(val) =>
                              setContentText4EN(val.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!showEdit && (
                  <div className="modal-footer">

                    <a className="btn btn-secondary" onClick={() => createIntro()}>
                      Add
                    </a>
                    <a className="btn btn-warning"  onClick={() => setShowModal(false)}>
                      Discard
                    </a>
                  </div>
              )}

              {showEdit && (
                  <div className="modal-footer">
                    <a className="btn btn-secondary" onClick={() => updateIntro()}>
                      Save
                    </a>
                    <a className="btn btn-warning" onClick={() => setShowModal(false)}>
                      Cancel
                    </a>
                  </div>
              )}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );

  function openModalEdit(element) {
    setEducation_name(element.educationName);
    setIsActive(element.isActive);
    setCountry(element.country);
    setSlug(element.slug);
    setContentTitle4(element.content.contentTitle4);
    setContentText4(element.content.contentText4);
    setContentTitle4EN(element.content.contentTitle4EN);
    setContentText4EN(element.content.contentText4EN);
    setContentButtonLabel4(element.content.contentButtonLabel4);
    setContentButtonUrl4(element.content.contentButtonUrl4);
    setShowSection4(element.content.showSection4);
    setShowButton4(element.content.showButton4);

    setItem(element);
    setShowModal(true);
    setShowEdit(true);
  }

  function openModalAdd() {
    setEducation_name(undefined);
    setIsActive(true);
    setCountry(undefined);
    setSlug(undefined);
    setItem(undefined);
    setContentTitle4(undefined);
    setContentText4(undefined);
    setContentTitle4EN(undefined);
    setContentText4EN(undefined);
    setContentButtonLabel4(undefined);
    setContentButtonUrl4(undefined);
    setShowSection4(true);
    setShowButton4(true);

    setShowModal(true);
    setShowEdit(false);
  }

  function fetchAllIntros() {
    getAllIntrosEventsCall()
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data.error);
      });
  }

  function createIntro() {
    const content = {
      contentTitle4,
      contentText4,
      contentTitle4EN,
      contentText4EN,
      contentButtonUrl4,
      contentButtonLabel4,
      showSection4,
      showButton4,
    };
    const data = {
      educationName: education_name,
      content,
      isActive,
      country,
      slug
    };
    const response = createIntroEventCall(data)
      .then((_) => {
        setShowModal(false);
        toast.success(response.message, toastOptions);
        fetchAllIntros();
      })
      .catch((error) => {
        handleError(error)
      });
  }

  function updateIntro() {
    const content = {
      contentTitle4,
      contentText4,
      contentTitle4EN,
      contentText4EN,
      contentButtonUrl4,
      contentButtonLabel4,
      showSection4,
      showButton4,
    };
    const data = {
      introductionId: item.id,
      educationName: education_name,
      content,
      isActive,
      country,
      slug
    };
    const response = updateIntroEventCall(data)
      .then((_) => {
        setShowModal(false);
        toast.success(response.message, toastOptions);
        fetchAllIntros();
      })
      .catch((error) => {
        handleError(error)
      });
  }
}
